import React from "react";
import InnerContactForm from "./InnerContactForm";
import InnerContactInfo from "./InnerContactInfo";

const InnerContactArea = () => {
  return (
    <section className="inner-contact-area">
      <div className="container">
        <div className="inner-contact-wrap">
          <div className="row">
            <div className="col-xl-9 col-lg-10">
              <div className="section-title title-style-two mb-50">
                {/* <h2 className="title">
                  Have a <span>Cool Project?</span> Get in touch!
                </h2> */}
                <p>Our team is available 24/7 to assist with your queries related to our services. We operate Monday to Saturday, 8:00 AM to 9:00 PM Indian Standard Time.</p>
                <p>For immediate support, call us at +91-120 477 4269 or email hello@kodeklan.com. We’re here to ensure your experience with us is smooth and enjoyable.</p>
              </div>

              <div className="inner-contact-form-wrap">
                <InnerContactForm />
              </div>

              <div id="contact-map">
                <iframe
                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.149001008647!2d77.37584617422263!3d28.62529608441159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff3260c6761%3A0x2ecd01b8bfe74df2!2sBSI%20Business%20Park%20H161%20Sector%2063%20Noida!5e0!3m2!1sen!2sin!4v1727675354275!5m2!1sen!2sin"
                  allowFullScreen
                  loading="lazy"
                />
              </div>

              <div className="inner-contact-info">
                <InnerContactInfo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InnerContactArea;
