import React from "react";
import ServicesAreaItem from "./ServicesAreaItem";

const ServicesArea = () => {
  const services_area_list = [
    {
      title: "Mobile App Development",
      desc: "Transform your ideas into dynamic mobile experiences. We craft user-friendly apps tailored to engage your audience and drive growth.",
      url: "/services-details",
      delay_time: 2,
    },
    {
      title: "Software Development",
      desc: "Empower your business with custom software solutions that streamline operations and enhance efficiency, tailored to meet your unique needs.",
     
      url: "/services-details",
      delay_time: 4,
    },
    {
      title: "Digital Transformation",
      desc: "Revolutionize your business processes with our digital transformation strategies, leveraging cutting-edge technology to elevate your operations and customer experience.",
      url: "/services-details",
      delay_time: 4,
    },
    {
      title: "Ideation and Designing",
      desc: "Bring your vision to life with our creative ideation and design services, where innovation meets aesthetics to create compelling user experiences.",
      url: "/services-details",
      delay_time: 4,
    },
    {
      title: "IT Consulting",
      desc: "Navigate the digital landscape with expert IT consulting that aligns technology with your business goals, ensuring strategic growth and efficiency.",
      delay_time: 4,
    },
    {
      title: "DevOps",
      desc: "Accelerate your development and operational processes with our DevOps solutions, fostering collaboration and continuous improvement for seamless project delivery.",
      url: "/services-details",
      delay_time: 4,
    },
    {
      title: "Cloud Managed Services",
      desc: "Maximize efficiency and scalability with our cloud managed services, providing secure, flexible solutions that adapt to your evolving business needs.",
      url: "/services-details",
      delay_time: 6,
    },
    {
      title: "All Services",
      desc: "",
      url: "/services-details",
      delay_time: 8,
    },
  ];

  return (
    <section className="services-area pt-35 pb-95">
      <div className="container">
        <div className="row justify-content-center">
          {services_area_list.map((item, index) => (
            <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
              <ServicesAreaItem index={index} item={item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesArea;
